export { EComponents } from './components';
export { EComponentTypes } from './types';
export { COMMON_PROPS, TEXT_PROPS, POSITIONING_PROPS, FLEX_PROPS, CSS_PROPS } from './props';

import FriendlyColPicture from '@/components/builder-pro/editor/atoms/parameters-settings/friendly-col-picture.vue';
import {
  FEATURE as FEATURE_ELEMENT,
  ROW as ROW_ELEMENT,
  COLUMN as COLUMN_ELEMENT,
  TEXT as TEXT_ELEMENT,
  IMAGE as IMAGE_ELEMENT,
  VIDEO as VIDEO_ELEMENT,
  YOUTUBE as YOUTUBE_ELEMENT,
  RUTUBE as RUTUBE_ELEMENT,
  IFRAME as IFRAME_ELEMENT,
  ACCORDION as ACCORDION_ELEMENT,
  BUBBLES as BUBBLES_ELEMENT,
  TABLE as TABLE_ELEMENT,
  SLIDER as SLIDER_ELEMENT,
  LINK as LINK_ELEMENT,
  POPOVER as POPOVER_ELEMENT,
  SCRIPT as SCRIPT_ELEMENT,
  THE360VIEWER as THE360VIEWER_ELEMENT,
  FORM as FORM_ELEMENT,
  FORM_INPUT as FORM_INPUT_ELEMENT,
  FORM_BUTTON as FORM_BUTTON_ELEMENT,
  FORM_CHECKBOX as FORM_CHECKBOX_ELEMENT,
  FORM_RADIO_GROUP as FORM_RADIO_GROUP_ELEMENT,
  FORM_RADIO_BUTTON as FORM_RADIO_BUTTON_ELEMENT,
  FORM_SELECT as FORM_SELECT_ELEMENT,
  BUBBLE as BUBBLE_ELEMENT,
  TABLE_ROW as TABLE_ROW_ELEMENT,
  TABLE_COLUMN as TABLE_COLUMN_ELEMENT,
  SLIDE as SLIDE_ELEMENT,
  POPOVER_HEADER as POPOVER_HEADER_ELEMENT,
  POPOVER_CONTENT as POPOVER_CONTENT_ELEMENT,
  ACCORDION_HEADER as ACCORDION_HEADER_ELEMENT,
  ACCORDION_CONTENT as ACCORDION_CONTENT_ELEMENT,
  THE360VIEWER_LOADER as THE360VIEWER_LOADER_ELEMENT,
  THE360VIEWER_ADDITIONAL_CONTENT as THE360VIEWER_ADDITIONAL_CONTENT_ELEMENT,
  TAB_BUTTON as TAB_BUTTON_ELEMENT,
  TAB_CONTENT as TAB_CONTENT_ELEMENT,
  TAB_BUTTON_WITH_STATE as TAB_BUTTON_WITH_STATE_ELEMENT,
  TAB_BUTTON_WITH_STATE_INACTIVE as TAB_BUTTON_WITH_STATE_INACTIVE_ELEMENT,
  TAB_BUTTON_WITH_STATE_ACTIVE as TAB_BUTTON_WITH_STATE_ACTIVE_ELEMENT,
  DOWNLOAD_BUTTON,
  AR_BANNER,
  AR_BANNER_BUTTON,
  FORM_ERROR as FORM_ERROR_ELEMENT,
  FORM_SELECT_SELECTOR as FORM_SELECT_SELECTOR_ELEMENT,
  FORM_SELECT_DROPDOWN as FORM_SELECT_DROPDOWN_ELEMENT,
  FORM_SELECT_OPTION as FORM_SELECT_OPTION_ELEMENT,
  FRIENDLY_ACCORDION,
  FRIENDLY_ACCORDION_CONTENT,
  FRIENDLY_ACCORDION_CONTENT_ITEM,
  FRIENDLY_ACCORDION_HEADER,
  FRIENDLY_BUBBLES,
  FRIENDLY_BUBBLES_LIST,
  FRIENDLY_BUBBLES_LIST_ITEM,
  FRIENDLY_COL2,
  FRIENDLY_COL3,
  FRIENDLY_COL4,
  FRIENDLY_COL_PICTURE,
  FRIENDLY_COMPARISON_CHART,
  FRIENDLY_COMPARISON_CHART_COL_IMAGE,
  FRIENDLY_COMPARISON_CHART_COL_TEXT,
  FRIENDLY_COMPARISON_CHART_ROW,
  FRIENDLY_DESCRIPTION,
  FRIENDLY_ICONS,
  FRIENDLY_IMAGE_SIDEBAR,
  FRIENDLY_IMAGE_TEXT_OVERLAY,
  FRIENDLY_IMAGE_TEXT_OVERLAY_LIST,
  FRIENDLY_IMAGE_TEXT_OVERLAY_LIST_ITEM,
  FRIENDLY_MULTIPLE_IMAGE_TEXT,
  FRIENDLY_MULTIPLE_IMAGE_TEXT_SLIDE,
  FRIENDLY_PICTURE,
  FRIENDLY_PICTURE_WITH_TEXT,
  FRIENDLY_SINGLE_IMAGE_HIGHLIGHTS,
  FRIENDLY_SINGLE_IMAGE_SPECS,
  FRIENDLY_SINGLE_LEFT_IMAGE,
  FRIENDLY_SINGLE_RIGHT_IMAGE,
  FRIENDLY_SPACER,
  FRIENDLY_SPECIFICATIONS,
  FRIENDLY_SPECIFICATIONS_COL,
  FRIENDLY_SPECIFICATIONS_ROW,
  FRIENDLY_SPECIFICATIONS_TABLE,
  FRIENDLY_TEXT_LIST,
  FRIENDLY_TEXT_LIST_ITEM,
  FRIENDLY_TITLE_DESCRIPTION,
  FRIENDLY_VIDEO,
  FRIENDLY_YOUTUBE,
  FRIENDLY_SLIDER,
  FRIENDLY_SLIDE,
  FRIENDLY_SLIDE_PICTURE,
  FRIENDLY_SLIDE_VIDEO,
  FRIENDLY_SLIDE_YOUTUBE,
  FRIENDLY_RUTUBE,
  FRIENDLY_SLIDE_RUTUBE,
  FRIENDLY_VK_VIDEO,
  FRIENDLY_SLIDE_VK_VIDEO,
  FRIENDLY_SLIDE_TEXT,
  OZON_COL2,
  OZON_COL3,
  OZON_COL4,
  OZON_COMPARISON_CHART,
  OZON_COMPARISON_CHART_COL_IMAGE,
  OZON_COMPARISON_CHART_COL_TEXT,
  OZON_COMPARISON_CHART_ROW,
  OZON_COMPARISON_CHART_TABLE,
  OZON_DESCRIPTION,
  OZON_ICONS,
  OZON_ICONS_ITEM,
  OZON_IMAGE_SIDEBAR,
  OZON_MULTIPLE_IMAGE_TEXT,
  OZON_PICTURE,
  OZON_PICTURE_WITH_TEXT,
  OZON_PICTURE_WITHOUT_MARGINS,
  OZON_PICTURE_WITHOUT_MARGINS_LIST_ITEM,
  OZON_SINGLE_IMAGE_HIGHLIGHTS,
  OZON_SINGLE_IMAGE_SPECS,
  OZON_SINGLE_LEFT_IMAGE,
  OZON_SINGLE_RIGHT_IMAGE,
  OZON_SPECIFICATIONS,
  OZON_SPECIFICATIONS_COL,
  OZON_SPECIFICATIONS_ROW,
  OZON_SPECIFICATIONS_TABLE,
  OZON_TEXT_LIST,
  OZON_TEXT_LIST_ITEM,
  OZON_TITLE_DESCRIPTION,
  OZON_VIDEO,
  OZON_YOUTUBE,
  AMAZON_COL2,
  AMAZON_COL3,
  AMAZON_COL4,
  AMAZON_COMPARISON_CHART,
  AMAZON_COMPARISON_CHART_COL_IMAGE,
  AMAZON_COMPARISON_CHART_COL_TEXT,
  AMAZON_COMPARISON_CHART_ROW,
  AMAZON_COMPARISON_CHART_TABLE,
  AMAZON_DESCRIPTION,
  AMAZON_ICONS,
  AMAZON_IMAGE_SIDEBAR,
  AMAZON_IMAGE_TEXT_OVERLAY,
  AMAZON_MULTIPLE_IMAGE_TEXT,
  AMAZON_MULTIPLE_IMAGE_TEXT_SLIDE,
  AMAZON_PICTURE,
  AMAZON_PICTURE_WITH_TEXT,
  AMAZON_SINGLE_IMAGE_HIGHLIGHTS,
  AMAZON_SINGLE_IMAGE_SPECS,
  AMAZON_SINGLE_LEFT_IMAGE,
  AMAZON_SINGLE_RIGHT_IMAGE,
  AMAZON_SPECIFICATIONS,
  AMAZON_SPECIFICATIONS_COL,
  AMAZON_SPECIFICATIONS_ROW,
  AMAZON_SPECIFICATIONS_TABLE,
  AMAZON_TEXT_LIST,
  AMAZON_TEXT_LIST_ITEM,
  AMAZON_TITLE_DESCRIPTION,
} from './elements';
export { DYNAMIC_DATA_COMPONENTS, DYNAMIC_DATA_COMPONENT_KEYS, DYNAMIC_DATA_COMPONENT_KEYS_OBJ } from './elements';

export const COMMON_ELEMENTS = {
  Row: ROW_ELEMENT,
  Column: COLUMN_ELEMENT,
  Text: TEXT_ELEMENT,
  Image: IMAGE_ELEMENT,
  Video: VIDEO_ELEMENT,
  Youtube: YOUTUBE_ELEMENT,
  Rutube: RUTUBE_ELEMENT,
  Iframe: IFRAME_ELEMENT,
  Accordion: ACCORDION_ELEMENT,
  Bubbles: BUBBLES_ELEMENT,
  Table: TABLE_ELEMENT,
  Slider: SLIDER_ELEMENT,
  Link: LINK_ELEMENT,
  Popover: POPOVER_ELEMENT,
  Script: SCRIPT_ELEMENT,
  DownloadButton: DOWNLOAD_BUTTON,
  The360Viewer: THE360VIEWER_ELEMENT,
  TabButton: TAB_BUTTON_ELEMENT,
  TabContent: TAB_CONTENT_ELEMENT,
  Form: FORM_ELEMENT,
  FormInput: FORM_INPUT_ELEMENT,
  FormButton: FORM_BUTTON_ELEMENT,
  FormCheckbox: FORM_CHECKBOX_ELEMENT,
  FormRadioGroup: FORM_RADIO_GROUP_ELEMENT,
  FormRadioButton: FORM_RADIO_BUTTON_ELEMENT,
  FormSelect: FORM_SELECT_ELEMENT,
  ArBanner: AR_BANNER,
};

export const FRIENDLY_ELEMENTS = {
  FriendlyAccordion: FRIENDLY_ACCORDION,
  FriendlyBubbles: FRIENDLY_BUBBLES,
  FriendlyCol2: FRIENDLY_COL2,
  FriendlyCol3: FRIENDLY_COL3,
  FriendlyCol4: FRIENDLY_COL4,
  FriendlyComparisonChart: FRIENDLY_COMPARISON_CHART,
  FriendlyDescription: FRIENDLY_DESCRIPTION,
  FriendlyImageSidebar: FRIENDLY_IMAGE_SIDEBAR,
  FriendlyImageTextOverlay: FRIENDLY_IMAGE_TEXT_OVERLAY,
  FriendlyIcons: FRIENDLY_ICONS,
  FriendlyMultipleImageText: FRIENDLY_MULTIPLE_IMAGE_TEXT,
  FriendlyPicture: FRIENDLY_PICTURE,
  FriendlyPictureWithText: FRIENDLY_PICTURE_WITH_TEXT,
  FriendlySingleImageHighlights: FRIENDLY_SINGLE_IMAGE_HIGHLIGHTS,
  FriendlySingleImageSpecs: FRIENDLY_SINGLE_IMAGE_SPECS,
  FriendlySingleLeftImage: FRIENDLY_SINGLE_LEFT_IMAGE,
  FriendlySingleRightImage: FRIENDLY_SINGLE_RIGHT_IMAGE,
  FriendlySpacer: FRIENDLY_SPACER,
  FriendlySpecifications: FRIENDLY_SPECIFICATIONS,
  FriendlyTitleDescription: FRIENDLY_TITLE_DESCRIPTION,
  FriendlyVideo: FRIENDLY_VIDEO,
  FriendlyYoutube: FRIENDLY_YOUTUBE,
  FriendlySlider: FRIENDLY_SLIDER,
  FriendlyRutube: FRIENDLY_RUTUBE,
  FriendlyVkVideo: FRIENDLY_VK_VIDEO,
};

export const OZON_ELEMENTS = {
  OzonCol2: OZON_COL2,
  OzonCol3: OZON_COL3,
  OzonCol4: OZON_COL4,
  OzonComparisonChart: OZON_COMPARISON_CHART,
  OzonDescription: OZON_DESCRIPTION,
  OzonIcons: OZON_ICONS,
  OzonMultipleImageText: OZON_MULTIPLE_IMAGE_TEXT,
  OzonImageSidebar: OZON_IMAGE_SIDEBAR,
  OzonPictureWithText: OZON_PICTURE_WITH_TEXT,
  OzonPictureWithoutMargins: OZON_PICTURE_WITHOUT_MARGINS,
  OzonSingleImageHighlights: OZON_SINGLE_IMAGE_HIGHLIGHTS,
  OzonSingleImageSpecs: OZON_SINGLE_IMAGE_SPECS,
  OzonSingleLeftImage: OZON_SINGLE_LEFT_IMAGE,
  OzonSingleRightImage: OZON_SINGLE_RIGHT_IMAGE,
  OzonSpecifications: OZON_SPECIFICATIONS,
  OzonTextList: OZON_TEXT_LIST,
  OzonTitleDescription: OZON_TITLE_DESCRIPTION,
  OzonVideo: OZON_VIDEO,
  OzonYoutube: OZON_YOUTUBE,
};

export const AMAZON_ELEMENTS = {
  AmazonCol2: AMAZON_COL2,
  AmazonCol3: AMAZON_COL3,
  AmazonCol4: AMAZON_COL4,
  AmazonComparisonChart: AMAZON_COMPARISON_CHART,
  AmazonDescription: AMAZON_DESCRIPTION,
  AmazonImageSidebar: AMAZON_IMAGE_SIDEBAR,
  AmazonImageTextOverlay: AMAZON_IMAGE_TEXT_OVERLAY,
  AmazonIcons: AMAZON_ICONS,
  AmazonMultipleImageText: AMAZON_MULTIPLE_IMAGE_TEXT,
  AmazonPicture: AMAZON_PICTURE,
  AmazonPictureWithText: AMAZON_PICTURE_WITH_TEXT,
  AmazonSingleImageHighlights: AMAZON_SINGLE_IMAGE_HIGHLIGHTS,
  AmazonSingleImageSpecs: AMAZON_SINGLE_IMAGE_SPECS,
  AmazonSingleLeftImage: AMAZON_SINGLE_LEFT_IMAGE,
  AmazonSingleRightImage: AMAZON_SINGLE_RIGHT_IMAGE,
  AmazonSpecifications: AMAZON_SPECIFICATIONS,
  AmazonTitleDescription: AMAZON_TITLE_DESCRIPTION,
};

export const BASE_COMPONENT_KEYS = Object.keys({
  ...COMMON_ELEMENTS,
  ...FRIENDLY_ELEMENTS,
  ...OZON_ELEMENTS,
  ...AMAZON_ELEMENTS,
});

export const ALL_ELEMENTS = {
  ...COMMON_ELEMENTS,
  ...FRIENDLY_ELEMENTS,
  ...OZON_ELEMENTS,
  ...AMAZON_ELEMENTS,
  Feature: FEATURE_ELEMENT,
  Bubble: BUBBLE_ELEMENT,
  Slide: SLIDE_ELEMENT,
  TableRow: TABLE_ROW_ELEMENT,
  TableColumn: TABLE_COLUMN_ELEMENT,
  PopoverHeader: POPOVER_HEADER_ELEMENT,
  PopoverContent: POPOVER_CONTENT_ELEMENT,
  AccordionHeader: ACCORDION_HEADER_ELEMENT,
  AccordionContent: ACCORDION_CONTENT_ELEMENT,
  The360ViewerLoader: THE360VIEWER_LOADER_ELEMENT,
  The360ViewerAdditionalContent: THE360VIEWER_ADDITIONAL_CONTENT_ELEMENT,
  TabButtonWithState: TAB_BUTTON_WITH_STATE_ELEMENT,
  TabButtonWithStateInactive: TAB_BUTTON_WITH_STATE_INACTIVE_ELEMENT,
  TabButtonWithStateActive: TAB_BUTTON_WITH_STATE_ACTIVE_ELEMENT,
  ArBannerButton: AR_BANNER_BUTTON,

  FormError: FORM_ERROR_ELEMENT,
  FormSelectSelector: FORM_SELECT_SELECTOR_ELEMENT,
  FormSelectDropdown: FORM_SELECT_DROPDOWN_ELEMENT,
  FormSelectOption: FORM_SELECT_OPTION_ELEMENT,

  FriendlyAccordionContent: FRIENDLY_ACCORDION_CONTENT,
  FriendlyAccordionContentItem: FRIENDLY_ACCORDION_CONTENT_ITEM,
  FriendlyAccordionHeader: FRIENDLY_ACCORDION_HEADER,
  FriendlyBubblesList: FRIENDLY_BUBBLES_LIST,
  FriendlyBubblesListItem: FRIENDLY_BUBBLES_LIST_ITEM,
  FriendlyColPicture: FRIENDLY_COL_PICTURE,
  FriendlyComparisonChartColImage: FRIENDLY_COMPARISON_CHART_COL_IMAGE,
  FriendlyComparisonChartColText: FRIENDLY_COMPARISON_CHART_COL_TEXT,
  FriendlyComparisonChartRow: FRIENDLY_COMPARISON_CHART_ROW,
  FriendlyMultipleImageTextSlide: FRIENDLY_MULTIPLE_IMAGE_TEXT_SLIDE,
  FriendlySpecificationsCol: FRIENDLY_SPECIFICATIONS_COL,
  FriendlySpecificationsRow: FRIENDLY_SPECIFICATIONS_ROW,
  FriendlySpecificationsTable: FRIENDLY_SPECIFICATIONS_TABLE,
  FriendlyTextList: FRIENDLY_TEXT_LIST,
  FriendlyTextListItem: FRIENDLY_TEXT_LIST_ITEM,
  FriendlySlide: FRIENDLY_SLIDE,
  FriendlySlidePicture: FRIENDLY_SLIDE_PICTURE,
  FriendlySlideVideo: FRIENDLY_SLIDE_VIDEO,
  FriendlySlideYoutube: FRIENDLY_SLIDE_YOUTUBE,
  FriendlySlideRutube: FRIENDLY_SLIDE_RUTUBE,
  FriendlySlideVkVideo: FRIENDLY_SLIDE_VK_VIDEO,
  FriendlySlideText: FRIENDLY_SLIDE_TEXT,
  FriendlyImageTextOverlayList: FRIENDLY_IMAGE_TEXT_OVERLAY_LIST,
  FriendlyImageTextOverlayListItem: FRIENDLY_IMAGE_TEXT_OVERLAY_LIST_ITEM,

  OzonComparisonChartColImage: OZON_COMPARISON_CHART_COL_IMAGE,
  OzonComparisonChartColText: OZON_COMPARISON_CHART_COL_TEXT,
  OzonComparisonChartRow: OZON_COMPARISON_CHART_ROW,
  OzonComparisonChartTable: OZON_COMPARISON_CHART_TABLE,
  OzonIconsItem: OZON_ICONS_ITEM,
  OzonPicture: OZON_PICTURE,
  OzonPictureWithoutMarginsListItem: OZON_PICTURE_WITHOUT_MARGINS_LIST_ITEM,
  OzonSpecificationsCol: OZON_SPECIFICATIONS_COL,
  OzonSpecificationsRow: OZON_SPECIFICATIONS_ROW,
  OzonSpecificationsTable: OZON_SPECIFICATIONS_TABLE,
  OzonTextListItem: OZON_TEXT_LIST_ITEM,

  AmazonComparisonChartColImage: AMAZON_COMPARISON_CHART_COL_IMAGE,
  AmazonComparisonChartColText: AMAZON_COMPARISON_CHART_COL_TEXT,
  AmazonComparisonChartRow: AMAZON_COMPARISON_CHART_ROW,
  AmazonComparisonChartTable: AMAZON_COMPARISON_CHART_TABLE,
  AmazonMultipleImageTextSlide: AMAZON_MULTIPLE_IMAGE_TEXT_SLIDE,
  AmazonSpecificationsCol: AMAZON_SPECIFICATIONS_COL,
  AmazonSpecificationsRow: AMAZON_SPECIFICATIONS_ROW,
  AmazonSpecificationsTable: AMAZON_SPECIFICATIONS_TABLE,
  AmazonTextList: AMAZON_TEXT_LIST,
  AmazonTextListItem: AMAZON_TEXT_LIST_ITEM,
};

export const FRIENDLY_OZON_ELEMENTS = {
  FriendlyColPicture: OZON_PICTURE_WITHOUT_MARGINS,
  FriendlyCol2: OZON_COL2,
  FriendlyCol3: OZON_COL3,
  FriendlyCol4: OZON_COL4,
  FriendlyComparisonChart: OZON_COMPARISON_CHART,
  FriendlyDescription: OZON_DESCRIPTION,
  FriendlyImageSidebar: OZON_IMAGE_SIDEBAR,
  //FriendlyImageTextOverlay: OZON_PICTURE_WITH_TEXT,
  FriendlyIcons: OZON_ICONS,
  FriendlyMultipleImageText: OZON_MULTIPLE_IMAGE_TEXT,
  FriendlyPicture: OZON_PICTURE_WITHOUT_MARGINS,
  FriendlyPictureWithText: OZON_PICTURE_WITH_TEXT,
  FriendlySingleImageHighlights: OZON_SINGLE_IMAGE_HIGHLIGHTS,
  FriendlySingleImageSpecs: OZON_SINGLE_IMAGE_SPECS,
  FriendlySingleLeftImage: OZON_SINGLE_LEFT_IMAGE,
  FriendlySingleRightImage: OZON_SINGLE_RIGHT_IMAGE,
  FriendlySpecifications: OZON_SPECIFICATIONS,
  FriendlyTextList: OZON_TEXT_LIST,
  //FriendlyTitleDescription: OZON_TITLE_DESCRIPTION,
  FriendlyVideo: OZON_VIDEO,
  FriendlyYoutube: OZON_YOUTUBE,
};

export const FRIENDLY_AMAZON_ELEMENTS = {
  FriendlyCol2: AMAZON_COL2,
  FriendlyCol3: AMAZON_COL3,
  FriendlyCol4: AMAZON_COL4,
  FriendlyComparisonChart: AMAZON_COMPARISON_CHART,
  FriendlyDescription: AMAZON_DESCRIPTION,
  FriendlyImageSidebar: AMAZON_IMAGE_SIDEBAR,
  FriendlyImageTextOverlay: AMAZON_IMAGE_TEXT_OVERLAY,
  FriendlyIcons: AMAZON_ICONS,
  FriendlyMultipleImageText: AMAZON_MULTIPLE_IMAGE_TEXT,
  FriendlyPicture: AMAZON_PICTURE,
  FriendlyPictureWithText: AMAZON_PICTURE_WITH_TEXT,
  FriendlySingleImageHighlights: AMAZON_SINGLE_IMAGE_HIGHLIGHTS,
  FriendlySingleImageSpecs: AMAZON_SINGLE_IMAGE_SPECS,
  FriendlySingleLeftImage: AMAZON_SINGLE_LEFT_IMAGE,
  FriendlySingleRightImage: AMAZON_SINGLE_RIGHT_IMAGE,
  FriendlySpecifications: AMAZON_SPECIFICATIONS,
  FriendlyTitleDescription: AMAZON_TITLE_DESCRIPTION,
};

export const FRIENDLY_TEFAL_ELEMENTS = {
  ...FRIENDLY_ELEMENTS,
  FriendlyCol2: FRIENDLY_COL3,
  FriendlyCol4: FRIENDLY_COL3,
}

export const FRIENDLY_MOULINEX_ELEMENTS = {
  ...FRIENDLY_ELEMENTS,
  FriendlyCol2: FRIENDLY_COL3,
  FriendlyCol4: FRIENDLY_COL3,
}

export const FRIENDLY_KRUPS_ELEMENTS = {
  ...FRIENDLY_ELEMENTS,
  FriendlyCol2: FRIENDLY_COL3,
  FriendlyCol4: FRIENDLY_COL3,
}

export const FRIENDLY_ROWENTA_ELEMENTS = {
  ...FRIENDLY_ELEMENTS,
  FriendlyCol2: FRIENDLY_COL3,
  FriendlyCol4: FRIENDLY_COL3,
}

export const FRIENDLY_WMF_ELEMENTS = {
  ...FRIENDLY_ELEMENTS,
  FriendlyCol2: FRIENDLY_COL3,
  FriendlyCol4: FRIENDLY_COL3,
}